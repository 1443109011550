<template>
  <div class="pb-3">
    <h1>FITP INFORMA</h1>
  </div>
  <!-- <div class="row" v-if="isEnabled('fnBachecaMsgPuntoInformativo')">
    <div class="px-3">
      <div class="alert alert-primary fs-6" role="alert">
        <b>Attenzione: </b> <br />
        In base ai dati risultanti al 30 settembre, è stata calcolata e
        riconosciuta direttamente nel borsellino una prima tranche del
        contributo parametrato al numero di tessere agonistiche, non agonistiche
        e socio.<br />Ti Ricordiamo che l’iniziativa resta attiva fino al
        31/12/2023.
      </div>
    </div>
  </div>
  <div class="row" v-if="isEnabled('fnBachecaMsgPuntoInformativo')">
    <div class="px-3">
      <div class="alert alert-success fs-6" role="alert">
        <b>Attenzione: </b> <br />
        nell’ambito del processo di riforma dell’ordinamento sportivo in corso
        di attuazione, la FITP comunica di avere istituito un
        <b>'Punto Informativo'</b> a cui gli affiliati potranno rivolgersi onde
        ottenere assistenza per ogni eventuale necessità propedeutica agli
        adeguamenti regolamentari previsti dalla citata riforma e, in ogni caso,
        per rendere la propria struttura conforme alle prescrizioni impartite
        dalla nuova normativa, con particolare riferimento alla conformità degli
        statuti ed al lavoro sportivo. <br /><br />
        <b>
          Il 'Punto Informativo' è accessibile mediante il seguente indirizzo
          e-mail:
          <a
            href="mailto:statuti.lavorosportivo@fitp.it"
            style="text-decoration: underline"
            >statuti.lavorosportivo@fitp.it</a
          >
        </b>
      </div>
    </div>
  </div> -->
  <div>
    <TableBachecaPublic
      @getBachecaList="getBachecaList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  //watch,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TableBachecaPublic from "@/components/components-fit/bacheca-public/TableBachecaPublic.vue";
import { useMsal } from "@/composition-api/useMsal";
import isEnabled from "@/composables/isEnabled.js";

export default defineComponent({
  name: "bacheca-public",
  components: {
    TableBachecaPublic,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Bacheca", []);
    });
    const store = useStore();
    const { accounts } = useMsal();
    console.log("test");

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(() => store.getters.rowsToSkipBacheca);
    const fetchRows = computed(() => store.getters.fetchRowsBacheca);
    const sortColumn = computed(() => store.getters.sortColumnBacheca);
    const sortOrder = computed(() => store.getters.sortOrderBacheca);

    const getBachecaList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.BACHECA_LIST,
        itemName: "bacheca_list",
      });
    };

    /*  const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      getBachecaList();
    }); */

    const resetFilters = () => {
      store.commit("resetFiltersBacheca");
      getBachecaList();
    };
    getBachecaList();

    return {
      getBachecaList,
      resetFilters,
      accounts,
      isEnabled,
    };
  },
});
</script>
